@import url(https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@300;400;600;700&family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}
.header {
  margin-bottom: 30px;
}
.topbar {
  background: #000033;
  padding: 10px 0px;
  border-bottom: solid 1px #808098;
}
.container {
  max-width: 1540px !important;
}
.company_logo {
  width: auto;
  max-height: 50px;
}
.custom-border {
  border-width: 2px !important; /* Set your desired border width */
  border-style: solid;
  border-radius: 10px !important; /* Ensure the border style is solid */
}

.muiLink-button {
  font-size: 1.2em;
  font-weight: 600;
}

.custom-grid-toolbar-button .MuiButton-label {
  font-size: 1.2em;
  font-weight: 600;
}

.MuiDataGrid-toolbarColumnsButton {
  /* Specificity increased to override Material-UI styles */
  font-size: 1.2em;
  font-weight: 600;
}

.MuiDataGrid-root .MuiDataGrid-toolbarContainer {
  display: flex;
  padding: 6px;
  border-bottom: solid 1px #ccc;
  background: #f1f1f1;
  align-items: center;
}

/* .header_background {
  background: url(../src/images/header-bg.jpg) no-repeat center center;
  background-size: cover;
  height: 120px;
  color: #fff;
} */
.header_background {
  background: #000232;
  /* height: 120px; */
  color: #fff;
}
.header_background .container {
  position: relative;
}

.open-xdr-loop-img {
  height: 120px;
  width: auto;
  position: absolute;
  top: 10px;
  right: 20px;
}

.header_background h1 {
  padding: 30px 0 10px;
  margin: 0px;
  font-size: 45px;
  text-align: left;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 600;
}
.header_background h4 {
  font-size: 18px;
  margin: 0px;
  color: #808098;
  text-transform: uppercase;
  font-weight: 600;
  text-align: left;
  padding: 0px 0 30px;
}
.input-group-prepend {
  display: flex;
  align-items: center;
  padding: 0px 15px;
  background-color: #176497;
  color: #fff;
  border-radius: 4px 0px 0px 4px;
}
.input-group-prepend i {
  margin-right: 4px;
}
.no_records_available {
  border-radius: 10px;
  margin-top: 20px;
  background: #fff7de;
  text-align: center;
  padding: 20px;
  border: solid 1px #ffe493;
}
.loader_background {
  border-radius: 10px;
  margin-top: 20px;
  background: #ecfcff;
  text-align: center;
  padding: 5px;
  border: solid 1px #b0bec0;
}
.loader_background img {
  max-height: 80px;
}
.no_records_available h4 {
  font-size: 16px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.panel {
  padding: 15px;
  border-radius: 5px !important;
  background-color: #eeeef1 !important;
  margin-top: 20px;
  border: none;
}
hr {
  margin: 10px 0px !important;
}

.close-icon {
  cursor: pointer;
}

.close-icon:hover img {
  filter: brightness(0) invert(1); /* This will invert the color of the image */
}

.close-icon:hover {
  color: #ffc107; /* Bootstrap warning color */
}

.close-icon:hover img {
  filter: brightness(0) saturate(100%) invert(67%) sepia(40%) saturate(500%)
    hue-rotate(330deg) brightness(105%) contrast(97%);
}

.custom-header {
  background-color: lightgrey;
  color: black;
}

.custom-modal .modal-content {
  border-radius: 30px;
  padding: 30px;
}

.btn.btn-warning {
  font-size: 16px !important;
  padding: 4px 10px !important;
  background-color: #f69220;
  padding: 7px 16px !important;
  border: none !important;
  color: #fff !important;
}
.legends {
  width: 620px;
  float: right;
  background: #fff;
  font-size: 13px;
  font-weight: 600;
  margin: 20px 4px 10px;
  border: solid 1px #ccc;
  border-radius: 4px;
  padding: 3px 10px;
}
.legends .color_code {
  width: 30px;
  height: 20px;
  margin-right: 5px;
  border-radius: 2px;
  display: block;
  float: left;
  background: #eee;
}
.panel-heading {
  font-size: 16px;
  font-weight: 600;
}
.panel-label {
  font-size: 16px;
  font-weight: 600;
}
label {
  font-weight: 500;
  font-size: 14px;
  margin-top: 10px !important;
  margin-bottom: 4px !important;
}
.custom-table {
  margin: 20px 0 40px;
  border: solid 1px #ccc;
  width: 100%;
  /* table-layout:fixed; */
}
.custom-table .long-column {
  column-width: "30%";
  table-layout: "auto";
}
.custom-table tr th {
  background: #003;
  color: #fff;
  padding: 6px 10px;
  font-size: 16px;
  border: none;
  font-weight: 800;
}
.custom-table tr.sub-title td {
  background: #f7ce92;
  font-size: 16px;
  font-weight: 600;
  padding: 6px 10px;
  color: #003;
  border: none;
}
.custom-table tr.table-heading td {
  background: #eee;
  font-size: 14px;
  padding: 6px 10px;
  font-weight: 600;
  color: #000;
}
.custom-table tr td {
  /* font-size:1em; */
  font-size: 14px;
  font-weight: 600;
  padding: 6px 10px;
}
.custom-table td.bysearch {
  color: #f69220;
  text-align: left;
}
.custom-table tr td .significance,
.significance {
  font-size: 9px;
  /* float: left; */
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 3px 6px 3px;
  text-align: center;
  line-height: normal;
  margin: 3px;
  border-radius: 2px;
  color: #fff;
  background: rgb(121, 121, 121);
  display: inline-block;
  cursor: pointer;
  white-space: nowrap;
}
.significance.required,
.legends .color_code.required {
  background: #d85656 !important;
  color: #fff !important;
}
.significance.optional,
.legends .color_code.optional {
  background: #77b94b !important;
  color: #fff !important;
}
.significance.recommended,
.legends .color_code.recommended {
  background: #9e5298 !important;
  color: #fff !important;
}
.significance.dependency,
.legends .color_code.dependency {
  background: #323977 !important;
  color: #fff !important;
}
.resultTable {
  margin: 0px 0px 80px;
  position: relative;
}
.custom-table tr td .search-tag {
  font-size: 8px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 1px 4px 2px;
  margin: 0px 2px;
  border-radius: 2px;
  color: #fff;
  background: #3d6cb3;
  display: inline-block;
}
.MuiDataGrid-root .MuiDataGrid-cell--withRenderer {
  flex-wrap: wrap;
}
.MuiDataGrid-root .MuiDataGrid-cell--textLeft,
.MuiDataGrid-root .MuiDataGrid-row {
  height: auto !important;
  min-height: initial !important;
  max-height: initial !important;
  white-space: normal !important;
  line-height: 22px !important;
}
.MuiDataGrid-root .MuiDataGrid-cell--textLeft {
  padding: 8px !important;
}
.MuiDataGrid-root .MuiDataGrid-dataContainer,
.MuiDataGrid-root .MuiDataGrid-viewport,
.MuiDataGrid-renderingZone,
.MuiDataGrid-windowContainer {
  max-height: initial !important;
  min-height: initial !important;
  position: relative !important;
  flex-wrap: wrap !important;
}
.MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRenderer,
.MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--editing {
  flex-wrap: wrap !important;
}
.MuiDataGrid-windowContainer {
  height: 100% !important;
}
.MuiDataGrid-root .MuiDataGrid-window {
  position: relative !important;
  top: 0px !important;
  margin-bottom: 56px !important;
}
.MuiDataGrid-root .MuiDataGrid-overlay {
  position: relative !important;
  padding: 56px !important;
  width: 100% !important;
  margin: 0px 0px 56px !important;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.MuiDataGrid-main > div:nth-child(2):not(.MuiDataGrid-columnsContainer) {
  height: 100% !important;
  position: relative !important;
  top: 56px !important;
}
.checkbox_options {
  padding: 0px 10px;
}
label.checkbox_options {
  font-weight: normal !important;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}
.auto-complete {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0px;
  max-height: 200px;
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
  position: absolute;
  background: #fff;
  z-index: 99999;
}

.suggestions li {
  padding: 0.4rem;
  background: #fff;
}

/* .suggestion-active {
  background-color: #84b3d1;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
} */
.suggestions li:hover {
  background-color: #d6efff;
  color: #222;
  cursor: pointer;
  font-weight: 400;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #eee;
}
.result-text {
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
  color: #666;
}
.unauthorized_container {
  text-align: center;
  height: 100%;
}
.unauthorized_container h2 {
  color: #000232;
  text-align: center;
}
.unauthorized_container h4 {
  color: #808098;
  text-align: center;
  font-weight: 400;
}
.unauthorized_container .btn {
  margin-top: 15px;
}
.footer {
  position: fixed;
  bottom: 0;
  /* top: calc( 100vh - 65px ); */
  margin-top: 20px;
  padding: 10px 10px 10px;
  background: #f1f1f1;
  color: #808098;
  width: 100%;
  font-size: 13px;
  text-align: center;
}
.footer strong {
  font-weight: 600;
}
.footer a {
  color: #f69220;
  font-weight: 600;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.action-btn {
  width: 120px;
  height: 40px;
  border-radius: 8px !important;
  font-size: 15px !important;
  font-weight: 400 !important;
}

/* Modal CSS Start */

.modal .modal-header,
.modal .modal-footer {
  border: none !important;
}

.modal .modal-title {
  font-size: 24px;
  line-height: 31.92px;
  font-weight: 600;
  color: #003b7c;
}

.modal .modal-body .modal-body-wrapper {
  /* height: 284px; */
  border-radius: 24px;
  border: 1px dotted #c5c5c5;
}

.modal .modal-body .modal-body-wrapper .modal-content {
  /* width: 184px; */
  /* height: 284px; */
  /* top: 33px; */
  margin-top: 20px;
  margin-bottom: 20px;
  /* left: 35%; */
  border: none;
  /* //border-radius: 6px; */
}

.modal .modal-body .modal-body-wrapper .form-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.modal .modal-body .modal-body-wrapper .form-container .form-group label {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.modal-content .upload-icon {
  text-align: center;
}

.modal-content .label-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  text-align: center;
  margin-top: 10px;
}

.modal-content .label-subtext {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #b5b5b5;
  text-align: center;
  margin-top: 5px;
}

.modal-content .button-box {
  text-align: center;
  margin-top: 10px;
}

.modal-content .button-box .btn-primary {
  width: 142px;
  height: 40px;
}

.modal-content .btn-primary {
  border-radius: 6px;
  background: #002c84;
}

.modal-content .button-box-subtext {
  margin-top: 5px;
  text-align: center;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0em;
  color: #b5b5b5;
}

.modal-content .button-box-subtext span {
  color: #ff0000;
}

.modal-content .file-counter {
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
}

.modal-content .modal-footer {
  justify-content: center;
}

.modal-custom-footer .btn-custom {
  width: 116px;
  height: 40px;
  border-radius: 6px;
}

/* Modal CSS End */

/** login-screen **/

.login-screen .login-container {
  width: 572px;
  height: 395px;
  top: 35%;
  left: calc(50% - 286px);
  position: absolute;
  border: 1px solid #b7b7b7;
  border-radius: 12px;
}

.login-screen .login-container .header {
  width: 572px;
  height: 71px;
  background: #000232;
  color: #f1f1f1;
  font-size: 25px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 12px 12px 0px 0px;
  padding-top: 17px;
  padding-left: 48px;
}

.login-screen .login-container .body form {
  margin: 42px;
}

/** Login Screen end **/

/** Signup Screen start **/

.login-screen .signup-container {
  width: 572px;
  height: 490px;
  top: 32%;
  left: calc(50% - 286px);
  position: absolute;
  border: 1px solid #b7b7b7;
  border-radius: 12px;
}

/** Signup Screen start **/

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.addNewDataSource {
  float: inline-end;
  color: #f7ac40;
  text-decoration: underline;
  cursor: pointer;
}

.dataSourceTags {
  border: 1px solid #ced4da;
  border-radius: 0.25em;
  min-height: 82px;
  height: auto;
}

iframe {
 display: none !important;
}

